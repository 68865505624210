import Phaser from 'phaser'

import store from '../redux/store';
import TilemapService from '../services/Tilemap.service';
import MovementService from '../services/Movement.service';
import ActionsService from '../services/Actions.service';
import WebhooksService from '../services/Webhooks.service';
import InitialisationService from '../services/Initialisation.service';
import PanelInitialisationService from '../services/Panel-initialisation.service'

import { BALANCE } from '../services/balance-config';

// A collection of pipelines can be found here https://github.com/photonstorm/phaser3-examples/tree/master/public/assets/pipelines

// Possible help? https://stackoverflow.com/questions/42805975/find-and-replace-color-in-texture2d-sprite

// Possible help? https://gamedevelopment.tutsplus.com/tutorials/how-to-use-a-shader-to-dynamically-swap-a-sprites-colors--cms-25129

const GrayscalePipeline = new Phaser.Class({

    Extends: Phaser.Renderer.WebGL.Pipelines.MultiPipeline,

    initialize:

    function OutlinePipeline(game) {
        Phaser.Renderer.WebGL.Pipelines.MultiPipeline.call(this,{
            game: game,
            renderer: game.renderer,
            fragShader: `
                precision mediump float;
                uniform sampler2D uMainSampler[%count%];
                uniform float gray;
                varying vec2 outTexCoord;
                varying float outTexId;
                varying vec4 outTint;
                varying vec2 fragCoord;
                void main()
                {
                    vec4 texture;
                    %forloop%
                    gl_FragColor = texture;
                    gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(0.999 * gl_FragColor.r + 0.7152 * gl_FragColor.g + 0.0722 * gl_FragColor.b), gray);
                }

            `,
            uniforms: [
                'uProjectionMatrix',
                'uMainSampler',
                'gray'
            ]

          })
    }
})

const OutlinePipeline = new Phaser.Class({

    Extends: Phaser.Renderer.WebGL.Pipelines.MultiPipeline,

    initialize:

    function OutlinePipeline(game) {
        Phaser.Renderer.WebGL.Pipelines.MultiPipeline.call(this,{
            game: game,
            renderer: game.renderer,
            fragShader: `
                precision mediump float;

                uniform sampler2D uMainSampler[%count%];
                uniform vec2 uResolution;
                uniform float uTime;

                varying vec2 outTexCoord;
                varying float outTexId;
                varying vec4 outTint;

                vec4 plasma()
                {
                    vec2 pixelPos = gl_FragCoord.xy / uResolution * 20.0;
                    float freq = 0.8;
                    float value =
                        sin(uTime + pixelPos.x * freq) +
                        sin(uTime + pixelPos.y * freq) +
                        sin(uTime + (pixelPos.x + pixelPos.y) * freq) +
                        cos(uTime + sqrt(length(pixelPos - 0.5)) * freq * 2.0);

                    return vec4(
                        cos(value),
                        sin(value),
                        sin(value * 3.14 * 2.0),
                        cos(value)
                    );
                }

                void main()
                {
                    vec4 texture;

                    %forloop%

                    texture *= vec4(outTint.rgb * outTint.a, outTint.a);

                    gl_FragColor = texture * plasma();
                }
            `,
            uniforms: [
                'uProjectionMatrix',
                'uViewMatrix',
                'uModelMatrix',
                'uMainSampler',
                'uResolution',
                'uTime'
            ]
          })
    }
})

export default class GameScene extends Phaser.Scene {
    tilemapService;
    movementService;
    farmingService;
    initialisationService;
    panelInitialisationService;

    walls;
    floor;

    constructor() {
        super('game-scene');

        this.tilemapService = new TilemapService(this);
        this.movementService = new MovementService(this, {});
        this.initialisationService = new InitialisationService();

        setTimeout(() => {
            this.audioService = this.game.audioService;
            this.actionsService = new ActionsService(this, {}, this.audioService);
        })
    }

    async init() {
        this.disableVisibilityChange = true;

        this.events.on('destroy', () => {
            this.tilemapService.onDestroy();
            this.tilemapService = null;
            this.movementService.onDestroy();
            this.movementService = null;
            this.actionsService.onDestroy();
            this.actionsService = null;
            // this.webhooksService.onDestroy();
            // this.webhooksService = null
            this.initialisationService = null;
            this.panelInitialisationService.onDestroy();
            this.panelInitialisationService = null;

            delete this.tilemapService;
            delete this.movementService;
            delete this.actionsService;
            // delete this.webhooksService
            delete this.initialisationService;
            delete this.panelInitialisationService;
        })
    }

    async preload() {
        this.tilemapService.preloadTilemap(this, {});

        this.grayscalePipeline = this.game.renderer.pipelines.add('Grayscale', new GrayscalePipeline(this));
        this.outlinePipeline = this.game.renderer.pipelines.add('Outline', new OutlinePipeline(this));

        try {
            setTimeout(() => {
                if (BALANCE.IS_AUDIO_DISABLED) {
                    return;
                }
                this.audioService.loadAudio(this);
            })
        } catch(e) {
            console.log('error!', e)
        }
    }

    async create() {
        let { map, floor, plants, walls } = this.tilemapService.createTilemap(this);


        this.panelInitialisationService = new PanelInitialisationService(this.tilemapService, this.audioService);

        await this.initialisationService.initialise();
        await this.panelInitialisationService.initialise();

        this.walls = walls;
        this.floor = floor;
        this.plants = plants;

        //not sure why this dispatch is necessary
        store.dispatch({ type: '', payload: {} })

        this.movementService.createInputListeners(this);
        this.actionsService.createInputListeners(this);

        this.audioService.playFieldRecording();
        setTimeout(() => {
            this.audioService.playBackgroundMusic();
        }, 5000)
    }

    update(time, delta) {
        this.tilemapService.updateTiles(time, delta);
    }
}
