import { BALANCE } from './balance-config';

class AudioService {
    phaser = undefined;

    audioFiles = [
        // Music
        {
            name: 'dry',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Dryspell_1.mp3',
        },
        {
            name: 'evergreen',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Evergreen_1.mp3',
        },
        {
            name: 'mountains',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Mayaya_2(sandy,%20mountains).mp3',
        },
        {
            name: 'tundra',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Octagonal_2.mp3',
        },
        {
            name: 'savannah',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Sav%20Anna_1.mp3',
        },
        {
            name: 'forests',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Shimmer-Dimmer_2(forests,%20fur%20forests,%20mines).mp3',
        },
        {
            name: 'steppes',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/Steppes.mp3',
        },
        {
            name: 'dom-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-1.mp3',
        },
        {
            name: 'dom-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-2.mp3',
        },
        {
            name: 'dom-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-3.mp3',
        },
        {
            name: 'dom-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-4.mp3',
        },
        {
            name: 'dom-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-5.mp3',
        },
        {
            name: 'dom-6',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-6.mp3',
        },
        {
            name: 'dom-7',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-7.mp3',
        },
        {
            name: 'dom-8',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/dom-song-8.mp3',
        },
        // Field Recordings
        {
            name: 'jungle-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/jungle-1.mp3',
        },
        {
            name: 'jungle-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/jungle-2.mp3',
        },
        {
            name: 'jungle-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/jungle-3.mp3',
        },
        {
            name: 'jungle-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/jungle-4.mp3',
        },
        {
            name: 'jungle-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/jungle-5.mp3',
        },
        // Sound
        {
            name: 'fight-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Attack.mp3',
            cooldown: 3000
        },
        {
            name: 'build-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_BuildingWalls_01.mp3',
            cooldown: 3000
        },
        {
            name: 'build-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_BuildingWalls_02.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_01.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_02.mp3',
            cooldown: 3000
        },
        {
            name: 'carpenter-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_03.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_04.mp3',
            cooldown: 3000
        },
        {
            name: 'carpenter-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_05.mp3',
            cooldown: 3000
        },
        {
            name: 'carpenter-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_06.mp3',
            cooldown: 3000
        },
        {
            name: 'carpenter-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_07.mp3',
            cooldown: 3000
        },
        {
            name: 'carpenter-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_08.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-9',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_09.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-10',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_10.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-11',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_11.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-12',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_12.mp3',
            cooldown: 3000
        },
        {
            name: 'lock',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_13.mp3',
            cooldown: 3000
        },
        {
            name: 'kitchen',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_14.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-15',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_15.mp3',
            cooldown: 3000
        },
        {
            name: 'collectWood-16',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_16.mp3',
            cooldown: 3000
        },
        {
            name: 'blacksmith',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ChoppingPlants_16.mp3',
            cooldown: 3000
        },
        {
            name: 'closeMenu',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CloseMenu.mp3',
            cooldown: 0
        },
        {
            name: 'collectItem',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_1.mp3',
            cooldown: 3000
        },
        {
            name: 'collectItem-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_1.mp3',
            cooldown: 3000
        },
        {
            name: 'collectItem-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_2.mp3',
            cooldown: 3000
        },
        {
            name: 'collectItem-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_3.mp3',
            cooldown: 3000
        },
        {
            name: 'collectItem-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_4.mp3',
            cooldown: 3000
        },
        {
            name: 'collectItem-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingItems_5.mp3',
            cooldown: 3000
        },
        {
            name: 'collectPlant-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_01.mp3',
            cooldown: 3000
        },
        {
            name: 'collectPlant-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_02.mp3',
            cooldown: 3000
        },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_03.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_04.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_05.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_06.mp3',
        //     cooldown: 3000
        // },
        {
            name: 'collectPlant-7',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_07.mp3',
            cooldown: 3000
        },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_08.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_09.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_10.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_11.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_12.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_13.mp3',
        //     cooldown: 3000
        // },
        // {
        //     name: '',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_CollectingPlants_14.mp3',
        //     cooldown: 3000
        // },
        {
            name: 'confirm', //unused!
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Confirm.mp3',
            cooldown: 3000
        },
        {
            name: 'hunger', //unused!
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_HungerAlert.mp3',
            cooldown: 3000
        },
        {
            name: 'generate', //unused!
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_OpeningSound.mp3',
            cooldown: 3000
        },
        {
            name: 'openMenu',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_OpenMenu.mp3',
            cooldown: 0
        },
        {
            name: 'potter',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Potter.mp3',
            cooldown: 3000
        },
        {
            name: 'fire',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_RangedAttackFireball.mp3',
            cooldown: 3000
        },
        {
            name: 'scrollMenu',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_ScrollMenu.mp3',
            cooldown: 0
        },
        // {
        //     name: 'speak-1',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_soundSpeaking_01.mp3',
        //     cooldown: 3000
        // },
        {
            name: 'speak-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_soundSpeaking_02.mp3',
            cooldown: 1000
        },
        // {
        //     name: 'speak-3',
        //     url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_soundSpeaking_03.mp3',
        //     cooldown: 3000
        // },
        {
            name: 'mason-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Stones.mp3',
            cooldown: 3000
        },
        {
            name: 'collectMineral',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Stones.mp3',
            cooldown: 3000
        },
        {
            name: 'collier-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves01.mp3',
            cooldown: 3000
        },
        {
            name: 'collier-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves02.mp3',
            cooldown: 3000
        },
        {
            name: 'collier-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves03.mp3',
            cooldown: 3000
        },
        {
            name: 'step-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves04.mp3',
            cooldown: 1000
        },
        {
            name: 'step-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves05.mp3',
            cooldown: 1000
        },
        {
            name: 'step-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves06.mp3',
            cooldown: 1000,
            volume: 0.3
        },
        {
            name: 'craft-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves07.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'craft-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves08.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'craft-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves09.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'tailor-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves10.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'tailor-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves11.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'tailor-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingLeaves12.mp3',
            cooldown: 3000,
            volume: 0.3
        },
        {
            name: 'doctor-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_01.mp3',
            cooldown: 3000
        },
        {
            name: 'doctor-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_02.mp3',
            cooldown: 3000
        },
        {
            name: 'doctor-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_03.mp3',
            cooldown: 3000
        },
        {
            name: 'doctor-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_04.mp3',
            cooldown: 3000
        },
        {
            name: 'tanner-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_05.mp3',
            cooldown: 3000
        },
        {
            name: 'tanner-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_06.mp3',
            cooldown: 3000
        },
        {
            name: 'tanner-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_07.mp3',
            cooldown: 3000
        },
        {
            name: 'tanner-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_08.mp3',
            cooldown: 3000
        },
        {
            name: 'tanner-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_WalkingWet_09.mp3',
            cooldown: 3000
        },
        {
            name: 'write-1',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Writing_01.mp3',
            cooldown: 3000
        },
        {
            name: 'write-2',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Writing_02.mp3',
            cooldown: 3000
        },
        {
            name: 'write-3',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Writing_03.mp3',
            cooldown: 3000
        },
        {
            name: 'write-4',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Writing_04.mp3',
            cooldown: 3000
        },
        {
            name: 'write-5',
            url: 'https://gliese-assets.nyc3.cdn.digitaloceanspaces.com/SG_Writing_05.mp3',
            cooldown: 3000
        },
    ];

    playedMusicTracks = [];

    playedFieldRecordingTracks = [];

    currentBiome = 'EMPTY';
    currentZIndex = 0;
    isInitialised = false;
    audioElement;

    cooldownSounds = []

    async loadAudio(phaser) {
        this.phaser = phaser;
        Promise.all(this.audioFiles.map(async ({name, url}) => {
            phaser.load.audio(name, url);
        }))
    }


    handleVisibilityChange(music) {
        if (document.hidden) {
            // Page is not in focus, do nothing to stop audio.
            console.log("Tab is inactive");
        } else {
            // Page is back in focus, ensure the audio continues.
            if (!music.isPlaying) {
                music.resume(); // Resume the audio if it's paused
            }
        }
    }

    playBackgroundMusic() {
        if (BALANCE.IS_AUDIO_DISABLED) {
            return;
        }
        const musicTracks = [
            'tundra',
            'dry',
            'evergreen',
            'mountains',
            'savannah',
            'forests',
            'steppes',
            'dom-1',
            'dom-2',
            'dom-3',
            'dom-4',
            'dom-5',
            'dom-6',
            'dom-7',
            'dom-8',
        ];

        const tracksToPlay = musicTracks.filter(track => (this.playedMusicTracks.indexOf(track) === -1));

        if (tracksToPlay.length === 0) {
            this.playedMusicTracks = [];
            this.playBackgroundMusic();
            return;
        }

        const trackToPlay = getRandomItemFromArray(tracksToPlay);

        const backgroundMusic = this.phaser.sound.add(trackToPlay, {
            loop: false,
            volume: 0.5
        });

        this.fadeIn(backgroundMusic, 1000);
        // this.fadeOutAtEnd(backgroundMusic, 3000);

        backgroundMusic.on('complete', function() {
            this.playedMusicTracks.push(trackToPlay);

            const FORTY_SECONDS = 1000 * 40;

            setTimeout(() => {
                this.playBackgroundMusic(); 
            }, FORTY_SECONDS)
        }.bind(this));
    }

    playFieldRecording() {
        if (BALANCE.IS_AUDIO_DISABLED) {
            return;
        }
        const fieldRecordingTracks = [
            'jungle-1',
            'jungle-2',
            'jungle-3',
            'jungle-4',
            'jungle-5',
        ];

        const tracksToPlay = fieldRecordingTracks.filter(track => (this.playedFieldRecordingTracks.indexOf(track) === -1));

        if (tracksToPlay.length === 0) {
            this.playedFieldRecordingTracks = [];
            this.playFieldRecording();
            return;
        }

        const trackToPlay = getRandomItemFromArray(tracksToPlay);

        const fieldRecording = this.phaser.sound.add(trackToPlay, {
            loop: false,
            volume: 0.5
        });

        this.fadeIn(fieldRecording, 1000);
        // this.fadeOutAtEnd(fieldRecording, 3000);

        fieldRecording.on('complete', function() {
            this.playedFieldRecordingTracks.push(trackToPlay);
            this.playFieldRecording(); 
        }.bind(this));
    }

    // TODO: Fade In function
    fadeIn(track, duration) {
        track.play();
    }


    playSound(soundName) {
        if (BALANCE.IS_AUDIO_DISABLED) {
            return;
        }

        const soundNameMatches = this.audioFiles.filter(file => (file.name.indexOf(soundName) > -1));
        const randomMatch = getRandomItemFromArray(soundNameMatches);

        soundName = randomMatch.name;

        // console.log('soundName', soundName)

        if (this.cooldownSounds.indexOf(soundName) === -1) {
            this.cooldownSounds.push(soundName);

            const soundDetails = this.audioFiles.find(file => (file.name === soundName))

            const detune = Phaser.Math.Between(-100, 100);
            const rate = Phaser.Math.FloatBetween(0.9, 1.1);
            soundDetails.volume = soundDetails.volume || 0.5;
            const volume = Phaser.Math.FloatBetween(soundDetails.volume - 0.1, soundDetails.volume + 0.1);

            const sound = this.phaser.sound.add(soundName, {
                detune,
                rate,
                volume,
            });
            sound.play();

            setTimeout(() => {
                this.cooldownSounds = this.cooldownSounds.filter(name => name !== soundName)
            }, soundDetails.cooldown || 0)
        }
    }

    setBiome(biome) {
        this.currentBiome = biome;
    }

    setZIndex(zIndex) {
        this.currentZIndex = zIndex;
    }
}

export default AudioService;

function getRandomItemFromArray(items) {
    return items[Math.floor(Math.random() * items.length)];
}